import DetectRTC from 'detectrtc'
import pack from '../package.json'

const _console = (window.console as any)
const METHODS = [
  'assert',
  'clear',
  'count',
  'countReset',
  'debug',
  'dir',
  'dirxml',
  'error',
  'exception',
  'group',
  'groupCollapsed',
  'groupEnd',
  'info',
  'log',
  'profile',
  'profileEnd',
  'table',
  'time',
  'timeEnd',
  'timeLog',
  'timeStamp',
  'trace',
  'warn'
]

_console.__logs = []
_console.getLogs = (): string => JSON.stringify(JSON.stringify(_console.__logs))
_console.__saveOutput = (name: string, ...args: any[]): void => {
  const row: string[] = [
    new Date().toUTCString(),
    name,
    ...(args || []).map((arg) => {
      if (arg) {
        try {
          if (Array.isArray(arg)) return JSON.stringify(arg)
          else if (arg instanceof Element) return arg.outerHTML
          else if (typeof arg === 'object') return JSON.stringify(arg)
          else if (typeof arg.toString === 'function') return arg.toString()

          // tslint:disable-next-line
        } catch (e) {}
      }
      return String(arg)
    })
  ]

  _console.__logs.push(row)
}

window.addEventListener('error', (e: any) => {
  const msg = e && e.error && e.error.message
  _console.__saveOutput('throw', msg)
  return false
})

window.addEventListener('unhandledrejection', (e: any) => {
  const msg = e && e.reason && e.reason.message
  _console.__saveOutput('unhandledrejection', msg)
  return false
})

METHODS.forEach((name: string) => {
  const origMethod = _console[name]
  _console[name] = (...args: any[]) => {
    _console.__saveOutput(name, ...args)
    return origMethod(...args)
  }
})

DetectRTC.load(() => {
  const defaultInfo = {
    osName: DetectRTC.osName,
    osVersion: DetectRTC.osVersion,
    browser: DetectRTC.browser,
    displayAspectRatio: DetectRTC.displayAspectRatio,
    displayResolution: DetectRTC.displayResolution,
    appVersion: pack.version
  }

  _console.__logs.unshift([defaultInfo])
})

